import { useTheme } from '@emotion/react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';

import { PROJECT_COLORS, PROJECT_ENVIRONMENTS } from '../utils';
import { ProgressRow } from './ProgressRow';

function ProjectGraphs(props) {
    const { graphs, tables, config, nbAnnotations } = props;
    const theme = useTheme();
    const styles = {
        graphSubTitle: { textAlign: 'center' },
        legendItem: {
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(2),
        },
        legendColorBox: {
            width: '12px',
            height: '12px',
            marginRight: theme.spacing(1),
            borderRadius: '50%',
        },
        legendText: {
            fontSize: '12px',
        },
        filter: {
            marginLeft: '10px',
            marginBottom: '4px',
        },
        card: {
            marginBottom: theme.spacing(3),
            backgroundColor: theme.palette.common.verylightgray,
        },
    };
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isVeryLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const [anchorEl, setAnchorEl] = useState({});
    const [showDetails, setShowDetails] = useState(graphs.map(() => false));

    const [filteredSubjects, setFilteredSubjects] = useState(
        graphs.map((graph) => graph.data.map((item) => item.subject)),
    );

    const handleFilterClick = (event, graphIndex) => {
        setAnchorEl((prev) => ({ ...prev, [graphIndex]: event.currentTarget }));
    };

    const handleClose = (graphIndex) => {
        setAnchorEl((prev) => ({ ...prev, [graphIndex]: null }));
    };

    const handleToggle = (graphIndex, subject) => {
        setFilteredSubjects((prevState) => {
            const newState = [...prevState];
            if (newState[graphIndex].includes(subject)) {
                newState[graphIndex] = newState[graphIndex].filter((subj) => subj !== subject);
            } else {
                newState[graphIndex] = [...newState[graphIndex], subject];
            }
            return newState;
        });
    };

    const getFontSize = () => {
        if (isSmallScreen) return '1.9vw';
        if (isMediumScreen) return '1.5vw';
        if (isLargeScreen) return '1.1vw';
        if (isVeryLargeScreen) return '0.7vw';
        return '0.8vw';
    };

    const getMaxLabelLength = () => {
        if (isSmallScreen) return 15;
        if (isMediumScreen) return 18;
        if (isLargeScreen) return 18;
        if (isVeryLargeScreen) return 18;
        return 18;
    };

    const renderCustomizedTick = (props) => {
        const { x, y, payload, cx, cy } = props;
        const element = payload?.value;

        if (!element) {
            return null;
        }
        let lines = [];
        const maxLineLength = getMaxLabelLength();
        const inLineElement = ['(', '-'];

        if (element.length < maxLineLength) {
            lines = [element];
        } else {
            const words = element.split(' ');
            let line = '';

            words.forEach((word, index) => {
                if (line.length + word.length < maxLineLength) {
                    if (inLineElement.some((el) => word.includes(el))) {
                        lines.push(line.trim());
                        line = '';
                    }
                    line += `${word} `;
                } else {
                    lines.push(line.trim());
                    line = `${word} `;
                }
            });

            if (line.trim()) {
                lines.push(line.trim());
            }

            if (lines.length === 2) {
                const maxLength = Math.max(lines[0].trim().length, lines[1].trim().length);
                lines = lines.map((line) => {
                    const spacesNeeded = maxLength - line.trim().length;
                    const leftPadding = '\u00A0'.repeat(Math.floor(spacesNeeded / 2) * 1.5);
                    const rightPadding = '\u00A0'.repeat(Math.ceil(spacesNeeded / 2) * 1.5);
                    return `${leftPadding}${line.trim()}${rightPadding}`;
                });
            }
        }

        const dx = x - cx;
        const dy = y - cy;
        const angle = Math.round(Math.atan2(dy, dx) * (180 / Math.PI));

        let adjustedX = x;
        let adjustedY = y;
        let anchor = 'middle';

        const labelOffsetX = 3;
        const labelOffsetY = 10;

        const absAngle = Math.abs(angle);
        const tmpAngle = absAngle > 90 ? 180 - absAngle : absAngle;
        const tempCoef = Math.abs(tmpAngle) / 90;
        const oppositeCoef = 1 - tempCoef;

        if (angle < 90 && angle > -90) {
            adjustedX += labelOffsetX * oppositeCoef;
            anchor = 'start';
        }

        if (angle > 90 || angle < -90) {
            adjustedX -= labelOffsetX * oppositeCoef;
            anchor = 'end';
        }

        if (angle < 180 && angle > 0) {
            adjustedY += labelOffsetY * tempCoef;
            if (tempCoef > 0.8) {
                adjustedY += 15;
            }
        }

        if (angle < 0 && angle > -180) {
            adjustedY -= labelOffsetY * tempCoef;
            if (tempCoef > 0.8) {
                adjustedY -= 20;
            }
        }

        return (
            <text
                x={adjustedX}
                y={adjustedY}
                fill={theme.palette.common.black}
                textAnchor={anchor}
                fontSize={getFontSize()}
            >
                {lines.map((line, index) => (
                    <tspan key={index} x={adjustedX} dy={index === 0 ? 0 : '1.2em'}>
                        {line}
                    </tspan>
                ))}
            </text>
        );
    };

    const CustomLegend = () => (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                paddingTop: theme.spacing(2),
                width: '100%',
            }}
        >
            {PROJECT_ENVIRONMENTS.map((env, index) => (
                <Box key={env} sx={styles.legendItem}>
                    <Box
                        sx={{
                            ...styles.legendColorBox,
                            backgroundColor: PROJECT_COLORS[index],
                        }}
                    />
                    <Typography variant='body2' component='span' sx={styles.legendText}>
                        {env}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
    const toggleShowDetails = (index) => {
        setShowDetails((prevState) => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    return (
        <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
            {graphs?.map(function (graph, index) {
                const table_data = tables[index];
                const config_data = config[index];

                return (
                    <Grid item key={index} xs={12} md={12} lg={12}>
                        <Card sx={styles.card}>
                            <CardContent>
                                <Box display='flex' justifyContent='center' alignItems='center'>
                                    <Typography variant='h6' style={styles.graphSubTitle}>
                                        {graph.title}
                                    </Typography>
                                    <IconButton onClick={(event) => handleFilterClick(event, index)} sx={styles.filter}>
                                        <FilterListIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl[index]}
                                        open={Boolean(anchorEl[index])}
                                        onClose={() => handleClose(index)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        sx={{ maxHeight: 300 }}
                                    >
                                        {graph.data.map((item, i) => (
                                            <MenuItem key={i}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={filteredSubjects[index].includes(item.subject)}
                                                            onChange={() => handleToggle(index, item.subject)}
                                                        />
                                                    }
                                                    label={item.subject}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        overflow: 'visible',
                                        padding: 0,
                                    }}
                                >
                                    <Grid container direction='row' alignItems='center' justifyContent='center'>
                                        <Box sx={{ textAlign: 'right', marginRight: '20px' }}>
                                            <Tooltip
                                                title={showDetails[index] ? 'Less Details' : 'More Details'}
                                                placement={'top'}
                                                arrow
                                            >
                                                <IconButton
                                                    onClick={() => toggleShowDetails(index)}
                                                    sx={{ marginTop: '32px' }}
                                                >
                                                    {showDetails[index] ? (
                                                        <ArrowBackIosIcon sx={{ paddingLeft: '8px' }} />
                                                    ) : (
                                                        <ArrowForwardIosIcon
                                                            sx={{ paddingRight: '4px', paddingLeft: '4px' }}
                                                        />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Grid item xs={10} md={10} lg={5}>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    minWidth: '300px',
                                                }}
                                            >
                                                {table_data.data
                                                    .filter((item) => filteredSubjects[index].includes(item.subject))
                                                    .map(function (item, i) {
                                                        const configElt = config_data?.data[i];
                                                        const graphElt = graph?.data[i];
                                                        const ticks = configElt?.rating?.values;
                                                        const intTicks = ticks.map((tick) => parseInt(tick));

                                                        return (
                                                            <ProgressRow
                                                                key={i}
                                                                testingValue={item.testing}
                                                                developmentValue={item.development}
                                                                progressTestingValue={graphElt.testing_progress}
                                                                progressDevelopmentValue={graphElt.development_progress}
                                                                devGraphValue={Math.floor(graphElt.development)}
                                                                testGraphValue={Math.floor(graphElt.testing)}
                                                                barSteps={intTicks}
                                                                subject={item.subject}
                                                                type={configElt?.rating?.type}
                                                                nbAnnotationTotal={nbAnnotations}
                                                                isHeader={i === 0}
                                                                showDetails={showDetails[index]}
                                                            />
                                                        );
                                                    })}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <ResponsiveContainer width='100%' aspect={1.3}>
                                                <RadarChart
                                                    data={graph.data.filter((item) =>
                                                        filteredSubjects[index].includes(item.subject),
                                                    )}
                                                    outerRadius='60%'
                                                >
                                                    <PolarGrid
                                                        gridType='circle'
                                                        stroke={theme.palette.common.black}
                                                        opacity={0.2}
                                                    />
                                                    <PolarAngleAxis
                                                        dataKey='subject'
                                                        stroke={theme.palette.common.black}
                                                        tick={renderCustomizedTick}
                                                        axisLineType={'circle'}
                                                        tickLine={false}
                                                        axisLine={false}
                                                    />
                                                    <PolarRadiusAxis
                                                        angle={90}
                                                        domain={[0, 3]}
                                                        tickCount={3}
                                                        ticks={[1, 2, 3]}
                                                        stroke={theme.palette.common.black}
                                                        tick={{
                                                            fontSize: 15,
                                                            textAnchor: 'middle',
                                                            dx: 10,
                                                            dy: 20,
                                                            zIndex: 10,
                                                        }}
                                                        axisLine={false}
                                                    />
                                                    {PROJECT_ENVIRONMENTS.map((env, envIndex) => (
                                                        <Radar
                                                            key={env}
                                                            name={env}
                                                            dataKey={env}
                                                            stroke={PROJECT_COLORS[envIndex]}
                                                            fillOpacity={0}
                                                            strokeOpacity={1}
                                                            strokeWidth={3}
                                                        />
                                                    ))}
                                                </RadarChart>
                                            </ResponsiveContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <CustomLegend />
                            </CardContent>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default ProjectGraphs;
