import { Box, LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { PROJECT_COLORS } from '../utils';

export const TestingProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: '5px 5px 0 0',
    backgroundColor: theme.palette.common.lightgray,
    '& .MuiLinearProgress-bar': {
        backgroundColor: PROJECT_COLORS[0],
    },
}));

export const DevelopmentProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: '0 0 5px 5px',
    backgroundColor: theme.palette.common.lightgray,
    '& .MuiLinearProgress-bar': {
        backgroundColor: PROJECT_COLORS[1],
    },
}));

export const VerticalLinesInBar = styled(Box)(({ steps, theme }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    pointerEvents: 'none',
    '& .bar': {
        borderLeft: `2px solid ${theme.palette.common.lightgray}`,
        height: '100%',
    },
}));
export const StepMarkers = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '20px',
}));

export const StepLabel = styled(Typography)(({ left }) => ({
    position: 'absolute',
    top: 0,
    left: `${left}%`,
    transform: 'translateX(-50%)',
}));
