import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { RATING_TYPES_OPTIONS } from '../panels/ProjectCreation/constant';
import { PROJECT_COLORS } from '../utils';
import { DevelopmentProgress, StepLabel, StepMarkers, TestingProgress, VerticalLinesInBar } from './ProgressBar';

export const ProgressRow = (props) => {
    const {
        testingValue,
        developmentValue,
        barSteps,
        subject,
        progressTestingValue,
        progressDevelopmentValue,
        type,
        nbAnnotationTotal,
        devGraphValue,
        testGraphValue,
        isHeader,
        showDetails,
    } = props;

    const [symbol] = useState(RATING_TYPES_OPTIONS.find((option) => option.value === type)?.symbol);
    const [steps] = useState(symbol === '#' ? [1, 2, 3] : barSteps);

    const styles = {
        outerBox: {
            width: '100%',
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'center',
            height: '40px',
        },
        subject: {
            color: 'white',
            textAlign: 'left',
            fontWeight: 'bold',
            width: '200px',
            paddingTop: '40px',
            marginRight: showDetails ? '20px' : '60px',
        },
        rowBody: {
            width: showDetails ? '20%' : '50%',
            paddingTop: `${isHeader ? 0 : 40}px`,
            marginRight: '20px',
            textAlign: 'right',
            fontWeight: 'bold',
            transition: 'width 0.5s ease',
        },
        annotationHeader: {
            textAlign: 'center',
            transition: 'width 0.5s ease',
            paddingTop: '5px',
            height: '42px',
        },
        numberColumn: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        numberLeftColumn: {
            width: '50%',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: PROJECT_COLORS[0],
            lineHeight: 0.8,
            display: 'flex',
        },
        numberLeftColumnText: {
            fontSize: '0.8rem',
            fontWeight: 'normal',
            textAlign: 'left',
            width: '70px',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            textOverflow: 'clip',
        },
        numberSpan: {
            fontSize: '14px',
            fontWeight: 'normal',
        },
        numberSeparator: {
            textAlign: 'center',
            fontWeight: 'normal',
            margin: '0 5px 3px 5px',
            borderLeft: '1px solid',
            width: '0',
            height: `${showDetails && symbol === '%' ? 30 : 20}px`,
        },
        numberRightColumn: {
            width: '50%',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: PROJECT_COLORS[1],
            lineHeight: 0.8,
            display: 'flex',
        },
        numberRightColumnText: {
            fontSize: '0.8rem',
            fontWeight: 'normal',
            textAlign: 'left',
            width: '70px',
            marginLeft: '20px',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            textOverflow: 'clip',
        },
        stepTitle: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            textOverflow: 'clip',
        },
        barBox: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
        },
        barInnerBox: {
            flexGrow: 1,
            position: 'relative',
        },
    };

    const calculateLeftPosition = (index) => {
        return index * (100 / steps.length);
    };

    return (
        <Box sx={styles.outerBox}>
            <Typography variant='body2' component='span' sx={styles.subject}>
                {subject}
            </Typography>

            <Box variant='body1' sx={styles.rowBody}>
                {isHeader && (
                    <Box sx={styles.annotationHeader}>
                        <Typography component='span' variant={'body2'} sx={{ fontWeight: 'bold' }}>
                            Annotations
                        </Typography>
                    </Box>
                )}
                <Box sx={styles.numberColumn}>
                    <Box sx={styles.numberLeftColumn}>
                        <Typography component='span' sx={styles.numberLeftColumnText}>
                            <CSSTransition
                                in={!showDetails}
                                timeout={200}
                                classNames='details-transition'
                                unmountOnExit
                            >
                                <>
                                    <span style={styles.numberSpan}>{'Level ' + testGraphValue}</span>
                                    <span style={styles.numberSpan}>
                                        {' (' + (testGraphValue > 0 ? steps[testGraphValue - 1] : 0) + symbol + ') '}
                                    </span>
                                </>
                            </CSSTransition>
                        </Typography>
                        <Box sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                            {testingValue}
                            {symbol === '%' && (
                                <CSSTransition
                                    in={showDetails}
                                    timeout={1}
                                    classNames='details-transition'
                                    unmountOnExit
                                >
                                    <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                                        <br /> ({((testingValue / nbAnnotationTotal) * 100).toFixed(0)}%)
                                    </span>
                                </CSSTransition>
                            )}
                        </Box>
                    </Box>

                    <Box sx={styles.numberSeparator}></Box>

                    <Box sx={styles.numberRightColumn}>
                        <Box sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                            {developmentValue}
                            {symbol === '%' && (
                                <CSSTransition
                                    in={showDetails}
                                    timeout={1}
                                    classNames='details-transition'
                                    unmountOnExit
                                >
                                    <span style={{ fontSize: '12px', fontWeight: 'normal', marginLeft: '8px' }}>
                                        <br /> ({((developmentValue / nbAnnotationTotal) * 100).toFixed(0)}%)
                                    </span>
                                </CSSTransition>
                            )}
                        </Box>

                        <Typography component='span' sx={styles.numberRightColumnText}>
                            <CSSTransition
                                in={!showDetails}
                                timeout={200}
                                classNames='details-transition'
                                unmountOnExit
                            >
                                <>
                                    <span style={styles.numberSpan}>{'Level ' + devGraphValue}</span>
                                    <span style={styles.numberSpan}>
                                        {' (' + (devGraphValue > 0 ? steps[devGraphValue - 1] : 0) + symbol + ') '}
                                    </span>
                                </>
                            </CSSTransition>
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <CSSTransition in={showDetails} timeout={300} classNames='details-transition' unmountOnExit>
                <Box sx={{ flexGrow: 1, transition: 'width 0.5s ease' }}>
                    {isHeader && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <StepMarkers>
                                {['', 'Level 1', 'Level 2', 'Level 3'].map((step, index) => (
                                    <StepLabel key={index} left={calculateLeftPosition(index)}>
                                        <Typography component='span' variant='body2' sx={styles.stepTitle}>
                                            {step}
                                        </Typography>
                                    </StepLabel>
                                ))}
                            </StepMarkers>
                        </Box>
                    )}

                    <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: `${isHeader ? 4 : 20}px` }}>
                        <StepMarkers>
                            {[0, ...steps].map((step, index) => (
                                <StepLabel key={index} left={calculateLeftPosition(index)}>
                                    <Typography
                                        variant='body2'
                                        component='span'
                                        sx={{ fontSize: '0.7rem', lineHeight: 2 }}
                                    >
                                        {step}
                                        <span style={{ fontSize: '0.6rem' }}>{symbol !== '<' && symbol}</span>
                                    </Typography>
                                </StepLabel>
                            ))}
                        </StepMarkers>
                    </Box>

                    <Box sx={styles.barBox}>
                        <Box sx={styles.barInnerBox}>
                            <TestingProgress variant='determinate' value={progressTestingValue} />
                            <VerticalLinesInBar steps={steps.length}>
                                {[0, ...steps].map((_, index) => {
                                    if (index === 0 || index === steps.length) {
                                        return <div key={index} />;
                                    }
                                    return <div key={index} className={'bar'} />;
                                })}
                            </VerticalLinesInBar>
                        </Box>
                    </Box>

                    <Box sx={styles.barBox}>
                        <Box sx={styles.barInnerBox}>
                            <DevelopmentProgress variant='determinate' value={progressDevelopmentValue} />
                            <VerticalLinesInBar steps={steps.length}>
                                {[0, ...steps].map((_, index) => {
                                    if (index === 0 || index === steps.length) {
                                        return <div key={index} />;
                                    }
                                    return <div key={index} className={'bar'} />;
                                })}
                            </VerticalLinesInBar>
                        </Box>
                    </Box>
                </Box>
            </CSSTransition>
        </Box>
    );
};
