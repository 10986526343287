import { useTheme } from '@emotion/react';
import {
    Box,
    Button,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { DELAY_BEFORE_ERROR, RATING_TYPES_OPTIONS } from '../../constant';

export const RatingSelectionForm = ({
    tables,
    handleRatingChange,
    handleSubmit,
    handleBack,
    editMode,
    setValidationCheck,
}) => {
    const theme = useTheme();
    const styles = {
        buttonContainer: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'flex-end',
        },
        section: {
            backgroundColor: theme.palette.common.sectionGray,
            margin: `4px 0 20px 0`,
            borderRadius: '5px',
            padding: theme.spacing(3),
        },
    };

    const allTableRatingSet = () => {
        for (let table of tables) {
            for (let subject of table.data) {
                if (!subject.rating || !subject.rating.type) {
                    return false;
                }

                const option = RATING_TYPES_OPTIONS.find((opt) => opt.value === subject.rating.type);
                if (!option) {
                    return false;
                }
                const nbFields = option.fields.length;
                const values = subject.rating.values.slice(0, nbFields);
                for (let value of values) {
                    if (!value) {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        const errorTimeout = setTimeout(() => {
            setShowErrors(true);
        }, DELAY_BEFORE_ERROR);

        return () => clearTimeout(errorTimeout);
    }, []);

    useEffect(() => {
        setValidationCheck(() => () => allTableRatingSet());
        return () => setValidationCheck(() => () => true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box>
            <Box textAlign='center'>
                <Typography variant='h5' sx={{ marginBottom: '24px' }}>
                    Ratings
                </Typography>
            </Box>
            {tables.map((table, tableIndex) => (
                <Box key={tableIndex} sx={styles.section}>
                    <Box textAlign='center'>
                        <Typography variant='h6'>{table.title}</Typography>
                    </Box>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label='rating table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Subject</TableCell>
                                    <TableCell>Rating Type</TableCell>
                                    <TableCell>Levels</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {table.data.map((subject, subjectIndex) => (
                                    <TableRow key={subjectIndex}>
                                        <TableCell>{subject.subject}</TableCell>
                                        <TableCell>
                                            <TextField
                                                select
                                                label='Rating Type'
                                                value={subject.rating?.type || ''}
                                                onChange={(e) =>
                                                    handleRatingChange(
                                                        tableIndex,
                                                        subjectIndex,
                                                        null,
                                                        'type',
                                                        e.target.value,
                                                    )
                                                }
                                                fullWidth
                                                SelectProps={{
                                                    native: false,
                                                    renderValue: (selected) => {
                                                        const option = RATING_TYPES_OPTIONS.find(
                                                            (opt) => opt.value === selected,
                                                        );
                                                        return option ? (
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                {option.icon}
                                                                <span style={{ marginLeft: 8 }}>{option.label}</span>
                                                            </Box>
                                                        ) : (
                                                            'Select Type'
                                                        );
                                                    },
                                                }}
                                            >
                                                {RATING_TYPES_OPTIONS.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            {option.icon}
                                                            <span style={{ marginLeft: 8 }}>{option.label}</span>
                                                        </Box>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </TableCell>

                                        {(
                                            RATING_TYPES_OPTIONS.find((opt) => opt.value === subject.rating?.type)
                                                ?.fields || []
                                        ).map((label, levelIndex) => (
                                            <TableCell key={levelIndex}>
                                                {subject.rating?.type === 'Boolean' ? (
                                                    <TextField
                                                        label={label}
                                                        value={subject.rating?.values?.[levelIndex] || ''}
                                                        onChange={(e) =>
                                                            handleRatingChange(
                                                                tableIndex,
                                                                subjectIndex,
                                                                levelIndex,
                                                                'value',
                                                                e.target.value,
                                                            )
                                                        }
                                                        fullWidth
                                                        select
                                                    >
                                                        <MenuItem value='True'>True</MenuItem>
                                                        <MenuItem value='False'>False</MenuItem>
                                                    </TextField>
                                                ) : (
                                                    <TextField
                                                        label={label}
                                                        value={subject.rating?.values?.[levelIndex] || ''}
                                                        onChange={(e) =>
                                                            handleRatingChange(
                                                                tableIndex,
                                                                subjectIndex,
                                                                levelIndex,
                                                                'value',
                                                                e.target.value,
                                                            )
                                                        }
                                                        fullWidth
                                                        type='number'
                                                    />
                                                )}
                                            </TableCell>
                                        ))}
                                        {RATING_TYPES_OPTIONS.find((opt) => opt.value === subject.rating?.type)?.fields
                                            .length < 3 && <TableCell></TableCell>}
                                        {!RATING_TYPES_OPTIONS.find((opt) => opt.value === subject.rating?.type) && (
                                            <>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ))}
            <Box sx={styles.buttonContainer}>
                {showErrors && !allTableRatingSet() && (
                    <Typography variant='body2' color='error'>
                        Please select a rating type and value for each element of each table row.
                    </Typography>
                )}
            </Box>
            <Box sx={styles.buttonContainer}>
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={handleSubmit} disabled={!allTableRatingSet()} variant={'contained'}>
                    {editMode ? 'Update' : 'Create'}
                </Button>
            </Box>
        </Box>
    );
};
