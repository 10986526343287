import { FileCopySharp } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Box, CircularProgress, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { actions } from '../../../actions';
import { api } from '../../../services';
import ProjectGraphs from '../Projects/components/ProjectGraphs';
import ProjectEditionPanel from '../Projects/panels/ProjectCreation/ProjectEditPanel';
import { getPanelUri } from '../index';
import ProjectTable from './components/ProjectTable';

const DEFAULT_BODY_HEIGHT = 184;

function ProjectDetailsPanel(props) {
    const { fetchProject, project, isLoading, setProjectDetails, clearProject } = props;
    const theme = useTheme();
    const { id } = useParams();
    const navigate = useNavigate();

    const [isTableVisible, setTableVisible] = useState(false);

    useEffect(() => {
        clearProject();
        if (id) {
            setProjectDetails(id);
            fetchProject(id);
        }
    }, [id, setProjectDetails, fetchProject, clearProject]);

    const styles = {
        container: {
            width: '100%',
            mb: theme.spacing(4),
        },
        paper: {
            width: '100%',
            position: 'relative',
            boxShadow: 1,
            padding: theme.spacing(3),
            boxSizing: 'border-box',
            maxHeight: `calc(100vh - ${DEFAULT_BODY_HEIGHT}px)`,
            overflowY: 'auto',
            overflowX: 'hidden',
            marginBottom: '4px',
        },
        header: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(2),
        },
        headerText: {
            fontWeight: 'bold',
            textAlign: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        loadingBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            margin: theme.spacing(3),
            padding: theme.spacing(3),
        },
        detailContent: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        detailContainer: {
            marginBottom: theme.spacing(3),
            width: '50%',
        },
        detailTitle: {
            textAlign: 'center',
            marginBottom: theme.spacing(2),
            fontWeight: 'bold',
        },
        tableContainer: {
            marginTop: theme.spacing(3),
            display: isTableVisible ? 'block' : 'none',
        },
        toggleButton: {
            display: 'flex',
            justifyContent: 'center',
        },
        edit: {
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    };

    const renderDetailItem = (icon, label, value) => (
        <Grid item xs={12} sm={6} md={3} align='center'>
            <Tooltip title={label} placement={'top'} arrow>
                <Box>
                    {icon}
                    <Typography>{value}</Typography>
                </Box>
            </Tooltip>
        </Grid>
    );

    const handleEditProject = () => {
        navigate(getPanelUri(<ProjectEditionPanel />, { id: id }));
    };

    return (
        <Box sx={styles.container}>
            <Paper sx={styles.paper}>
                <Box sx={styles.header}>
                    <Typography variant='h5' sx={styles.headerText}>
                        {isLoading ? 'Loading...' : project ? project?.name || 'Project not found' : 'Loading...'}
                    </Typography>
                </Box>
                {isLoading ? (
                    <Box sx={styles.loadingBox}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box sx={styles.detailContent}>
                            <Box style={styles.edit}>
                                <Box align='center'>
                                    <Tooltip title='New Project'>
                                        <IconButton onClick={handleEditProject}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Grid container spacing={2}>
                                {project?.graph_data && (
                                    <Grid item xs={12}>
                                        <ProjectGraphs
                                            graphs={project?.graph_data}
                                            tables={project?.table_data}
                                            config={project?.config}
                                            nbAnnotations={project?.nb_annotations}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                        <Box sx={styles.toggleButton}>
                            <Tooltip title={isTableVisible ? 'Hide Tables' : 'Show Tables'} placement={'top'} arrow>
                                <IconButton onClick={() => setTableVisible(!isTableVisible)}>
                                    {isTableVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={styles.tableContainer}>
                            <Box sx={styles.header}>
                                <Box sx={styles.detailContainer}>
                                    <Grid container spacing={2} justifyContent='center'>
                                        {renderDetailItem(
                                            <CalendarTodayIcon />,
                                            'Created At',
                                            project?.created_at
                                                ? new Date(project?.created_at).toLocaleDateString()
                                                : 'N/A',
                                        )}
                                        {renderDetailItem(
                                            <FileCopySharp />,
                                            'Number of Annotations',
                                            project?.nb_annotations || 'N/A',
                                        )}
                                        {renderDetailItem(
                                            <TextSnippetIcon />,
                                            'Number of Testing Annotations',
                                            project?.nb_annotations && project?.nb_annotations_dev
                                                ? project?.nb_annotations - project?.nb_annotations_dev
                                                : 'N/A',
                                        )}
                                        {renderDetailItem(
                                            <AssignmentIcon />,
                                            'Number of Developments Annotations',
                                            project?.nb_annotations_dev || 'N/A',
                                        )}
                                    </Grid>
                                </Box>
                            </Box>
                            {project?.table_data?.map((graph, index) => (
                                <ProjectTable key={index} title={graph.title} data={graph.data} />
                            ))}
                        </Box>
                    </>
                )}
            </Paper>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    project: state.data[api.endpoints.projectRefresh]?.data || null,
    isLoading: state.data[api.endpoints.projectRefresh]?.isFetching || false,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProject: (id) => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.projectRefresh, id })),
    setProjectDetails: (id) => dispatch(actions.project.details.set(id)),
    clearProject: () => dispatch(actions.api.data.reset({ endpoint: api.endpoints.projectRefresh })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsPanel);
