import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { Box, CardActionArea, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actions } from '../../../actions';
import { api } from '../../../services';
import ProjectDetailsPanel from '../ProjectDetails';
import { getPanelUri } from '../index';
import ProjectCard from './components/ProjectCard';
import ProjectCreationPanel from './panels/ProjectCreation/ProjectCreationPanel';

const DEFAULT_BODY_HEIGHT = 280;

function ProjectPanel(props) {
    const { fetchProjects, projects, clearProjects } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    const styles = {
        paper: {
            width: '100%',
            position: 'relative',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            marginBottom: '4px',
        },
        add: {
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        outPaper: { [theme.breakpoints.down('lg')]: { marginBottom: theme.spacing(4) } },
        title: {
            fontWeight: 'bold',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            textAlign: 'center',
            height: '5rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        topPage: { margin: '0 16px' },
        subTitle: { textAlign: 'center', marginBottom: theme.spacing(2) },
        queryCard: { margin: theme.spacing(3), backgroundColor: theme.palette.common.verylightgray },
    };

    const openProject = (project) => {
        navigate(getPanelUri(<ProjectDetailsPanel />, { id: project.id }));
    };

    const normalizeProjects = (projects) => {
        return Array.isArray(projects) ? projects : [projects];
    };

    const handleNewProject = () => {
        navigate(getPanelUri(<ProjectCreationPanel />));
    };

    useEffect(() => {
        clearProjects();
        fetchProjects();
    }, [fetchProjects, clearProjects]);

    return (
        <Box sx={styles.outPaper}>
            <Paper style={styles.paper}>
                <Box sx={styles.topPage}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <Typography variant='h5' sx={styles.title}>
                            Data Readiness
                        </Typography>
                    </Box>
                </Box>
                <Box style={styles.add}>
                    <Box align='center'>
                        <Tooltip title='New Project'>
                            <IconButton onClick={handleNewProject}>
                                <CreateNewFolderIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>

                <Grid container spacing={1}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginTop: theme.spacing(3),
                            [theme.breakpoints.up('md')]: {
                                height: `calc(100vh - ${DEFAULT_BODY_HEIGHT}px)`,
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            },
                        }}
                    >
                        <Grid container spacing={2}>
                            {normalizeProjects(projects)?.map((project) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={project.id}>
                                    <Box sx={{ padding: 1 }}>
                                        <CardActionArea onClick={() => openProject(project)} sx={{ borderRadius: 2 }}>
                                            <ProjectCard project={project} />
                                        </CardActionArea>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        projects: state.data[api.endpoints.project]?.data || [],
        graphs: state.data[api.endpoints.projectsHomeGraph]?.data || [],
        isLoading: state.data[api.endpoints.project]?.isFetching || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjects: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.project })),
        clearProjects: () => dispatch(actions.api.data.reset({ endpoint: api.endpoints.project })),
        fetchHomeGraph: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.projectsHomeGraph })),
        clearHomeGraph: () => dispatch(actions.api.data.reset({ endpoint: api.endpoints.projectsHomeGraph })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPanel);
