import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PercentIcon from '@mui/icons-material/Percent';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import React from 'react';

export const DEFAULT_BODY_HEIGHT = 168;
export const URL_SAVE_INTERVAL = 5 * 1000;
export const VALIDATION_BUTTONS = '.validation-button-query';
export const TOAST_MESSAGE_POPUP = '.SnackbarItem-variantError';
export const VALIDATE_BUTTONS_TIMEOUT = 300;
export const VALIDATION_MAX_RETRY = 10;
export const DEFAULT_TABLE_VALUE = [{ title: 'New Table', data: [{ subject: '', query: [] }] }];
export const DELAY_BEFORE_ERROR = 1000;

export const DEFAULT_FIELDS = ['Level 1', 'Level 2', 'Level 3'];
export const RATING_TYPES_OPTIONS = [
    { value: 'Percentage', label: 'Percentage', icon: <PercentIcon />, fields: DEFAULT_FIELDS, symbol: '%' },
    { value: 'Min Value', label: 'Min Value', icon: <FormatListNumberedIcon />, fields: DEFAULT_FIELDS, symbol: '<' },
    { value: 'Hardcoded', label: 'Hardcoded', icon: <ToggleOnIcon />, fields: ['Development', 'Testing'], symbol: '#' },
];
